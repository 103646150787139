import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import { notNull } from "@warrenio/utils/notNull";
import { link } from "../../components/Action.tsx";
import { BadgeDot } from "../../components/Badge.tsx";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { ContentPane } from "../../components/ContentPane.tsx";
import { ResourceWithIcon } from "../../components/ResourceWithIcon.tsx";
import { Separator } from "../../components/Separator.tsx";
import { Spacer } from "../../components/Spacer.tsx";
import { ViewTitle } from "../../components/Title.tsx";
import { WMeter } from "../../components/WMeter.tsx";
import { LongDate } from "../../components/l10n/DateFormat.tsx";
import { DetailsHolder, DetailsTable } from "../../components/table/DetailsTable.tsx";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import { LocationDetail } from "../../modules/location/LocationDetail.tsx";
import { AdminLinkButton } from "../AdminLinkButton.tsx";
import { VmOrderFields } from "../graphql.gen/graphql.ts";
import { buildTableExactSearch } from "../itemLinks.ts";
import type { HostWithStorage } from "./HostsTable.tsx";
import { OsBadgeButton } from "./OsBadge.tsx";
import { PoolBadgeButton } from "./PoolBadge.tsx";
import { StatusBadgeButton } from "./StatusBadge.tsx";

export function HostDetailView({ item }: { item: HostWithStorage }) {
    return (
        <>
            <HostDetails item={item} />
            <Separator />
        </>
    );
}

function HostDetails({ item }: { item: HostWithStorage }) {
    const {
        name,
        ip,
        uuid,
        location,
        created_at,
        pool_cpu_model,
        storage_pool_name,
        allocated_vcpu,
        vcpu_free,
        allocated_vmem,
        vmem_free,
        usable_vcpu_amount,
        usable_vmem_amount,
    } = item;

    const cpuTooltip = (
        <div>
            <BadgeDot color="color-primary">
                vCPU allocated: <b>{allocated_vcpu.toFixed(0)}</b>
            </BadgeDot>

            <BadgeDot color="color-primary-hover-alpha">
                vCPU available: <b>{vcpu_free.toFixed(0)}</b>
            </BadgeDot>
        </div>
    );

    const ramTooltip = (
        <div>
            <BadgeDot color="color-primary">
                vRAM allocated: <b>{allocated_vmem.toFixed(0)}</b> GB
            </BadgeDot>

            <BadgeDot color="color-primary-hover-alpha">
                vRAM available: <b>{vmem_free.toFixed(0)}</b> GB
            </BadgeDot>
        </div>
    );

    const cpuPercent = (allocated_vcpu / usable_vcpu_amount) * 100;
    const ramPercent = (allocated_vmem / usable_vmem_amount) * 100;

    return (
        <ContentPane className="VStack gap-4">
            <ViewTitle
                title={name ?? ""}
                // TODO: Remove global margins from all headings and instead use container-based layouts (eg. gap)
                // (otherwise it's impossible to lay out optional (nullable) components nicely without passing additional margin properties into every one of them)
                className="important:pb-0 important:min-h-auto"
            />

            <DetailsHolder>
                <DetailsTable>
                    <DetailsTableRow title="Name">{name}</DetailsTableRow>

                    <DetailsTableRow title="Type:">
                        <ResourceWithIcon type="virtual_machine" />
                    </DetailsTableRow>

                    <DetailsTableRow title="OS:">
                        <OsBadgeButton item={item} />
                    </DetailsTableRow>

                    <DetailsTableRow title="Status:">
                        <StatusBadgeButton item={item} />
                    </DetailsTableRow>

                    <DetailsTableRow title="Host/IP:">
                        <ClipBoardTooltip isHtml>{ip}</ClipBoardTooltip>
                    </DetailsTableRow>

                    <DetailsTableRow title="vCPU:">
                        <div className="max-w-10rem">
                            <WMeter title="vCPU allocation" value={cpuPercent} tooltip={cpuTooltip} />
                        </div>
                    </DetailsTableRow>

                    <DetailsTableRow title="vRAM:">
                        <div className="max-w-10rem">
                            <WMeter title="vRAM allocation" value={ramPercent} tooltip={ramTooltip} />
                        </div>
                    </DetailsTableRow>

                    <DetailsTableRow title="VMs:">
                        <HostVmsLink uuid={uuid} />
                    </DetailsTableRow>
                </DetailsTable>

                <Spacer />

                <DetailsTable>
                    <DetailsTableRow title="UUID:">
                        <ClipBoardTooltip>{uuid}</ClipBoardTooltip>
                    </DetailsTableRow>

                    <LocationDetail slug={notNull(location)} />

                    <DetailsTableRow title="Pool Name:">
                        <PoolBadgeButton item={item} />
                    </DetailsTableRow>

                    <DetailsTableRow title="Pool CPU Model:">{pool_cpu_model}</DetailsTableRow>

                    <DetailsTableRow title="Storage Pool Name:">{storage_pool_name}</DetailsTableRow>

                    <DetailsTableRow title="Created:">
                        {created_at ? <LongDate date={apiDatetimeToDate(created_at)} /> : "-"}
                    </DetailsTableRow>
                </DetailsTable>
            </DetailsHolder>
        </ContentPane>
    );
}

export function HostVmsLink({ uuid }: { uuid: string }) {
    return (
        <AdminLinkButton
            action={link({
                to: "/admin/virtual_machines",
                search: buildTableExactSearch(VmOrderFields.HostUuid, uuid),
            })}
            label="View All"
        />
    );
}
