import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import { useAtomValue } from "jotai/react";
import { atom } from "jotai/vanilla";
import z from "zod";
import { mergeLoadedQueries } from "../../utils/query/mergeQueries.ts";
import { adminAllStoragePoolsQueryAtom } from "../buckets/storagePoolsQuery.ts";
import { gf, type GqlFieldsOf } from "../FieldConfig.tsx";
import { GraphqlTable } from "../GraphqlTable.tsx";
import { locationField, numberField } from "../table_fields/standardFields.tsx";
import { useZodSearchParams } from "../useZodParse.tsx";
import { HostDetailView, HostVmsLink } from "./HostDetailView.tsx";
import { adminAllHostsQueryAtom, type AdminHostWithType } from "./hostQuery.ts";
import { OsBadgeButton } from "./OsBadge.tsx";
import { PoolBadgeButton } from "./PoolBadge.tsx";
import { StatusBadgeButton } from "./StatusBadge.tsx";

function Vmem({ value }: { value: number }) {
    return `${value.toFixed(1)} GB`;
}

function Vcpu({ value }: { value: number }) {
    return value.toFixed(1);
}

export interface HostWithStorage extends AdminHostWithType {
    storage_pool_name: string;
}

const hFields: GqlFieldsOf<
    HostWithStorage,
    /* No order type since this is not a real query */
    never
> = [
    gf({
        id: "uuid",
        title: "UUID",
        get: (a) => a.uuid,
        copyable: true,
    }),
    gf({
        id: "created_at",
        title: "Created At",
        get: (a) => (a.created_at ? apiDatetimeToDate(a.created_at) : undefined),
    }),
    gf({
        id: "name",
        title: "Name",
        get: (a) => a.name,
    }),
    gf({
        id: "ip",
        title: "Host/IP",
        get: (a) => a.ip,
        copyable: true,
    }),
    gf({
        id: "os",
        title: "OS",
        get: (a) => a.os,
        render: OsBadgeButton,
    }),
    gf({
        ...numberField,
        id: "usable_vcpu_amount",
        title: "Usable vCPUs",
        get: (a) => a.usable_vcpu_amount,
        render: Vcpu,
    }),
    gf({
        ...numberField,
        id: "vcpu_free",
        title: "Free vCPUs",
        get: (a) => a.vcpu_free,
        render: Vcpu,
    }),
    gf({
        ...numberField,
        id: "usable_vmem_amount",
        title: "Usable vMEM",
        get: (a) => a.usable_vmem_amount,
        render: Vmem,
    }),
    gf({
        ...numberField,
        id: "vmem_free",
        title: "Free vMEM",
        get: (a) => a.vmem_free,
        render: Vmem,
    }),
    gf({
        id: "pool_cpu_model",
        title: "CPU Model",
        get: (a) => a.pool_cpu_model,
        hidden: true,
    }),
    gf({
        id: "pool_uuid",
        title: "Pool UUID",
        get: (a) => a.pool_uuid,
        copyable: true,
        hidden: true,
    }),
    gf({
        id: "pool_name",
        title: "Pool Name",
        get: (a) => a.pool_name,
        render: PoolBadgeButton,
    }),
    gf({
        id: "storage_pool_uuid",
        title: "Storage Pool UUID",
        get: (a) => a.storage_pool_uuid,
        copyable: true,
        hidden: true,
    }),
    gf({
        id: "storage_pool_name",
        title: "Storage Pool Name",
        get: (a) => a.storage_pool_name,
    }),
    gf({
        id: "resources",
        title: "VMs",
        get: (a) => a,
        render: ({ item }) => <HostVmsLink uuid={item.uuid} />,
    }),
    gf({
        id: "status",
        title: "Status",
        get: (a) => a.status,
        render: StatusBadgeButton,
    }),
    gf({
        ...locationField,
        get: (a) => a.location,
    }),
];

const allHostsWithStorageQueryAtom = atom((get) =>
    mergeLoadedQueries(
        {
            hosts: get(adminAllHostsQueryAtom),
            pools: get(adminAllStoragePoolsQueryAtom),
        },
        ({ hosts, pools }) =>
            [...hosts.values()].map(
                (h): HostWithStorage => ({
                    // Join storage pool to host
                    ...h,
                    storage_pool_name: h.storage_pool_uuid
                        ? (pools.get(h.storage_pool_uuid)?.display_name ?? "<missing>")
                        : "-",
                }),
            ),
    ),
);

const SearchSchema = z
    .object({
        hostPoolId: z.string(),
    })
    .partial();

export function HostsTable() {
    const { hostPoolId } = useZodSearchParams(SearchSchema) ?? {};
    const predicate = (item: HostWithStorage) => hostPoolId === undefined || item.pool_uuid === hostPoolId;

    return (
        <GraphqlTable
            title="Hypervisors"
            fields={hFields}
            getId={(item) => item.uuid}
            // TODO: Fake query, support non-GraphQL data in a better way too
            useQuery={(_variables) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const { data, isPending, error } = useAtomValue(allHostsWithStorageQueryAtom);
                return { data: data ? { items: data.filter(predicate) } : undefined, loading: isPending, error };
            }}
            renderDetail={(item) => <HostDetailView key={item.uuid} item={item} />}
        />
    );
}
